import { ref } from 'vue'
import { useSubmit } from '@/application/composables/submit.js'

export function useWebhookAlertsUpdating(license) {
  const {
    webhook: {
      preferences: {
        message_scheduled_enabled,
        message_failed_enabled,
        message_sent_enabled,
        message_reply_enabled,
        message_reaction_enabled,
        message_timeout_enabled,
        conversation_inited_enabled,
        group_created_enabled,
        inbound_call_enabled,
      },
    },
  } = license
  const formdata = ref({
    message_scheduled: message_scheduled_enabled,
    message_failed: message_failed_enabled,
    message_sent: message_sent_enabled,
    message_reply: message_reply_enabled,
    message_reaction: message_reaction_enabled,
    message_timeout: message_timeout_enabled,
    conversation_inited: conversation_inited_enabled,
    group_created: group_created_enabled,
    inbound_call_enabled: inbound_call_enabled,
  })
  const {
    submit,
    checkTouched,
    isTouchedData,
    isDisabled,
    errorMessage,
  } = useSubmit(formdata.value, license, 'changeWebhookAlertsState')
  return {
    submit: (data) => submit({ webhook: data }),
    formdata,
    checkTouched,
    isTouchedData,
    isDisabled,
    errorMessage,
  }
}
