<template>
<!--  <div class="page-header-title">-->
<!--    <h5 class="m-b-10">{{ $t('webhooks') }}</h5>-->
<!--  </div>-->
  <div
    class="task-detail-right"
  >
    <div class="card">
      <div class="card-header">
        <h5>{{ $t('webhooks') }}</h5>
      </div>
      <div class="card-block task-details">
        <webhook-updating-form
          url-field-name="webhook_url"
          header-field-name="webhook_header"
          :license="license"
        ></webhook-updating-form>
      </div>
    </div>
  </div>
  <div
    class="task-detail-right"
  >
    <div class="card">
      <div class="card-header">
        <h5>{{ $t('sandboxWebhook') }}</h5>
      </div>
      <div class="card-block task-details">
        <webhook-updating-form
          url-field-name="sandbox_webhook_url"
          header-field-name="sandbox_webhook_header"
          :license="license"
        ></webhook-updating-form>
      </div>
    </div>
  </div>
  <div
    class="task-detail-right"
  >
    <div class="card">
      <div class="card-header">
        <h5>{{ $t('enabledWebhooksAlerts') }}</h5>
      </div>
      <div class="card-block task-details">
        <preferences-form
          :license="license"
          :formdata="formdata"
          :is-touched-data="isTouchedData"
          :is-disabled="isDisabled"
          :error-message="errorMessage"
          model="webhookAlerts"
          @update="checkTouched($event)"
          @submit="submit"
        ></preferences-form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
import { useWebhookAlertsUpdating } from '@/application/composables/webhookAlertsUpdating.js'

export default {
  name: 'LicenseDetailWebhooks',
  components: {
    'webhook-updating-form': defineAsyncComponent(() => import('@/application/components/forms/WebhookUpdatingForm.vue')),
    'preferences-form': defineAsyncComponent(() => import('@/application/components/forms/PreferencesForm.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      formdata,
      checkTouched,
      isTouchedData,
      isDisabled,
      submit,
      errorMessage,
    } = useWebhookAlertsUpdating(props.license)
    return {
      formdata,
      checkTouched,
      isTouchedData,
      isDisabled,
      submit,
      errorMessage,
    }
  },
}
</script>
